import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../components/layout'

// Icons
import iconCoffee from '../images/coffee.svg';
import iconCarService from '../images/car_service.svg';
import iconCarWash from '../images/car_wash.svg';
import iconCafe from '../images/cafe.svg';
import iconEcommerce from '../images/ecommerce.svg';
import iconB2B from '../images/b2b.svg';
import iconRetail from '../images/retail.svg';
import iconHR from '../images/hr.svg';

const SolutionsPage = () => (
  <Layout>
    <div className="container">
      <h2 id="coffee">Кофейня</h2>
      <img src={iconCoffee} alt="Кофейня" className="icon" />
      <p>
        Выручка кофейни зависит от трафика. Покупка ситуационная. Поэтому удачное местоположение это половина дела.
        <br/>
        Вторая половина – лояльность, которая важна в условиях насыщенного рынка и сопоставимого качества продукта. Закладывайте свои условия в программу лояльности. Если кофейня находится в бизнес-центре, торговом комплексе – введите скидки для постоянных клиентов.
        <br/>
        Если на оживленном перекрестке – стимулируйте промо-активность покупателей, предлагая вкусные бонусы или скидки за публикации и ссылки в социальных сетях.
      </p>

      <h2 id="cafe">Кафе</h2>
      <img src={iconCafe} alt="Кафе" className="icon" />
      <p>
        Хотите привлечь постоянных клиентов, например, сотрудников соседнего офисного центра? Не обязательно зазывать с улицы или печатать дорогостоящие карты клиента, чтобы на бизнес-ланч заходили именно к вам.
        <br/>
        Система лояльности позволит идентифицировать покупателя по номеру телефона или e-mail адресу и сделать скидку или начислить бонус.
        <br/>
        Вводите скидки на завтраки, обеды или ужины, блюда дня или предлагайте постоянным клиентам комплимент от шеф-повара. Используйте накопленные данные о клиентах. Например, сделайте специальное предложение по проведению банкета в день рождения. Оповестите о возможности по смс или e-mail.
      </p>

      <h2 id="ecommerce">Интернет-магазин</h2>
      <img src={iconEcommerce} alt="Интернет-магазин" className="icon" />
      <p>
        Для интернет-магазина внедрение программы лояльности напрашивается само собой. Отслеживайте покупателей, собирайте о них подробную информацию.  «Догоняйте» рекламой. Как превратить случайного клиента в постоянного? Фиксируя покупателей, стимулируйте более частые покупки через прогрессивную систему скидок.
      </p>
      <p>Увеличивайте средний чек, сделайте сопутствующие товары дешевле для тех, кто уже покупал у вас. </p>
      <p>
        Интегрируйте программу лояльности с CRM-системой и мессенджерами и напоминайте о себе клиентам, которые давно не покупали. Отличный вариант для интернет-магазина – предложить участникам программы лояльности бесплатную доставку.
      </p>

      <h2 id="carwash">Автомойка</h2>
      <img src={iconCarWash} alt="Автомойка" className="icon" />
      <p>
        Гуру маркетинга Филипп Котлер утверждал: привлечь нового покупателя стоит в 5-10 раз дороже, чем удержать старого. Удерживайте покупателей через программы лояльности.  Достаточно зарегистрировать личный кабинет на платформе LoyaltyPro, без выпуска дорогих пластиковых карт.
        <br/>
        Из всех вариантов (накопление бонусов, плата за привилегии) для автомойки самой привлекательной будет партнерская программа с другими участниками рынка: автосервисами, салонами, заправками. Поменял резину на зимнюю в любимом сервисе – получи чистую машину всего за половину цены. И наоборот. Надежный вариант привязать к себе клиента.
      </p>

      <h2 id="carservice">Авторемонтная мастерская</h2>
      <img src={iconCarService} alt="Авторемонтная мастерская" className="icon" />
      <p>
        Большинство клиентов авторемонтных мастерских – постоянные. Ведь хорошие мастера ценятся на вес золота. Если покупатели и так возвращаются к вам, зачем внедрять программу лояльности?
        <br/>
        Дело в том, что программы работают не только на удержание.  Вы сможете повысить средний чек, привлечь новых клиентов за счет рекомендаций и сарафанного радио.
        <br/>
        Вы сможете анализировать информацию по заказам и определять востребованные и невостребованные услуги. Аналитика подскажет, что выгоднее – расширять линейку сервисов,  уйти в нишу или нанять еще двух сотрудников под покрасочные работы.
        <br/>
        Анализируйте динамику заказов и концентрируйтесь на самых перспективных услугах. Предложите участникам программы лояльности бесплатную диагностику систем автомобиля.
      </p>

      <h2 id="b2b">Партнерская сеть (b2b)</h2>
      <img src={iconB2B} alt="Партнерская сеть (b2b)" className="icon" />
      <p>
        B2b – это продажи через партнерскую сеть конечным потребителям – физлицам или юридическим лицам. Важно то, что ваш бизнес отказывается от функции прямых продаж, передавая эту привилегию партнерам или дилерам.
        <br/>
        Во взаимодействии с партнерами действует принцип Парето: 80% эффекта будет достигнуто усилиями 20% партнеров. Продумайте программу поощрения за самые высокие продажи. Возможно, это будет прогрессивная схема отчислений, возможно – иные бонусы.  Благодаря платформе LoyalyPro можно привлекать новых партнеров в свою сеть при помощи методики look-alike (привлечение аудитории, сходной по поведению с выбранным и изученным вами сегментом).
      </p>

      <h2 id="retail">Розничные магазины</h2>
      <img src={iconRetail} alt="Розничные магазины" className="icon" />
      <p>
        Программы лояльности для розницы стали традицией. Они помогают вырастить продажи, повысить средний чек, улучшать результаты по другим метрикам. Например, LTV – показатель жизненной ценности клиента, который показывает, сколько денег принес покупатель за всю историю взаимодействия с компанией.
        <br/>
        Чем больше преданных клиентов – тем  меньше необходимость тратить существенные средства на рекламу. Чем меньше отток клиентов – тем выше прибыль.
        <br/>
        Эти показатели можно отслеживать через сервис LoyaltyPro. В личном кабинете вы сможете посмотреть показатель оттока (не превышает ли он норму для небольших компаний в 3-5%). Для покупателей программа лояльности даст чувство причастности, что особенно важно, если вы работаете в премиум-сегменте.
      </p>

      <h2 id="hr">Мотивация сотрудников</h2>
      <img src={iconHR} alt="Мотивация сотрудников" className="icon" />
      <p>Конкуренция за специалистов сегодня ведется на уровне ценностей.
        Что важно вашим сотрудникам? Как вы хотите их поощрять? Исследуйте предпочтения своего персонала и запускайте одну или несколько программ лояльности. Например, для офисного и производственного персонала.
      </p>
      <p>
        Поощряйте сотрудников скидками на покупку продукции компании, возможностью накопления бонусов и траты их на дополнительные отгулы. Предложите бонусы за помощь в закрытии вакансий. Программа лояльности открывает большие возможности для внутреннего PR. Стройте рейтинги сотрудников, проводите внутренние конкурсы, премируйте по их результатам.
      </p>
      <p>
        Следите за степенью вовлеченности сотрудников в программу лояльности. Преданный компании сотрудник в свою очередь обеспечит вам лояльных клиентов.
      </p>
    </div>
  </Layout>
)

export default SolutionsPage
